import React from 'react';
import { FormattedMessage as L } from 'react-intl';
import Link from 'components/Link';
import globalStyles from 'pages/pages.module.css';
import s from './PrivacyContainer.module.css';

const PrivacyContainer = () => (
  <div className={s.root}>
    <h1><L id="privacy.title" defaultMessage="Hiveon Privacy Policy" /></h1>
    <p><i><L id="privacy.effective" defaultMessage="Effective from October 16, 2017" /></i></p>
    <p>
      <L
        id="privacy.text1"
        defaultMessage="Thank you for choosing Hiveon! Hiveon ('us', 'we', or 'our') operates the {hiveosHref}, {forumHref}, {hiveonHref} websites (the 'Services')."
        values={{
          hiveosHref: <a href="https://hiveos.farm/" className={globalStyles.link}>https://hiveos.farm</a>,
          forumHref: <Link external noRef href="https://forum.hiveos.farm/" className={globalStyles.link}>https://forum.hiveos.farm</Link>,
          hiveonHref: <Link external noRef href="https://www.hiveon.net/" className={globalStyles.link}>https://www.hiveon.net</Link>,
        }}
      />
    </p>
    <p>
      <L id="privacy.text2" defaultMessage="This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Services and the choices you have associated with that data." />
    </p>
    <p>
      <L
        id="privacy.text3"
        defaultMessage="We use your data to provide and improve the Services. By using the Services, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from {termsHref}"
        values={{
          termsHref: <Link withLang to="/terms" className={globalStyles.link}><L id="privacy.termsOfUse" defaultMessage="Terms of Use" /></Link>
        }}
      />
    </p>
    <h2><L id="privacy.collection.title" defaultMessage="Information Collection And Use" /></h2>
    <p><L id="privacy.collection.text1" defaultMessage="We collect several different types of information for various purposes to provide and improve our Services to you." /></p>
    <h2><L id="privacy.collection.text2" defaultMessage="Types of Data Collected" /></h2>
    <h3><L id="privacy.collection.text3" defaultMessage="Personal Data" /></h3>
    <p><L id="privacy.collection.text4" defaultMessage="While using our Services, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ('Personal Data'). Personally identifiable information may include, but is not limited to:" /></p>
    <ul>
       <li><L id="privacy.collection.text5" defaultMessage="Email address" /></li>
       <li><L id="privacy.collection.text6" defaultMessage="Username" /></li>
       <li><L id="privacy.collection.text7" defaultMessage="Farm IDs" /></li>
       <li><L id="privacy.collection.text8" defaultMessage="Cookies and Usage Data" /></li>
    </ul>
    <h3><L id="privacy.usageData.title" defaultMessage="Usage Data" /></h3>
    <p><L id="privacy.usageData.text1" defaultMessage="We may also collect information how the Services are accessed and used ('Usage Data'). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Services that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data." /></p>
    <h3><L id="privacy.usageData.text2" defaultMessage="Tracking and Cookies Data" /></h3>
    <h4><L id="privacy.usageData.text3" defaultMessage="We use cookies and similar tracking technologies to track the activity on our Services and hold certain information." /></h4>
    <p><L id="privacy.usageData.text4" defaultMessage="Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Services." /></p>
    <p><L id="privacy.usageData.text5" defaultMessage="You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Services." /></p>
    <p><L id="privacy.usageData.text6" defaultMessage="Examples of Cookies we use:" /></p>
    <ul>
       <li><L id="privacy.usageData.text7" defaultMessage="Session Cookies. We use Session Cookies to operate our Services." /></li>
       <li><L id="privacy.usageData.text8" defaultMessage="Analytics Cookies. We use analytics cookies to improve the quality of our Services." /></li>
    </ul>
    <p><L id="privacy.listCookie.title" defaultMessage="List of cookies we use:" /></p>
    <ul>
        <li><b><L id="privacy.listCookie.text1" defaultMessage="Cookies in the account (the.hiveos.farm):" /></b>
          <ul>
            <li><L id="privacy.listCookie.text2" defaultMessage="necessary authentication cookies (jwtt)" /></li>
            <li><L id="privacy.listCookie.text3" defaultMessage="necessary Cloudflare cookies (__cfduid)" /></li>
            <li><L id="privacy.listCookie.text4" defaultMessage="analytics cookies (_gat, _gat_conversionTracker, _ga, _gid)" /></li>
          </ul>
        </li>
      <li><b><L id="privacy.listCookie.text5" defaultMessage="Cookies on the website (hiveos.farm):" /></b>
          <ul>
            <li><L id="privacy.listCookie.text6" defaultMessage="necessary Intercom cookies (intercom-id-[app_id], intercom-session-[app_id])" /></li>
            <li><L id="privacy.listCookie.text7" defaultMessage="necessary Cloudflare cookies (__cfduid)" /></li>
            <li><L id="privacy.listCookie.text8" defaultMessage="Analytics, Facebook and Google cookies (_ga, _gid, _fbp, _gat)" /></li>
            <li><L id="privacy.listCookie.text9" defaultMessage="Settings cookies (settings-gdpr-GA-accept, settings-gdpr-GTM-accept, settings-gdpr-FB-accept)" /></li>
          </ul>
        </li>
    </ul>
    <p><L id="privacy.functionsCookies.title" defaultMessage="The functions of the cookies we use:" /></p>
    <ul>
      <li><L id="privacy.functionsCookies.text1" defaultMessage="jwtt — the session ID" /></li>
      <li><L id="privacy.functionsCookies.text2" defaultMessage="__cfduid — cookie associated with websites using CloudFlare (required to speed up page loading)" /></li>
      <li><L id="privacy.functionsCookies.text3" defaultMessage="_gat — limits the frequency of requests" /></li>
      <li><L id="privacy.functionsCookies.text4" defaultMessage="_gat_conversionTracker — limits the frequency of requests" /></li>
      <li><L id="privacy.functionsCookies.text13" defaultMessage="_gali — cookie is associated with Google Analytics. This cookie is used to collect information about how visitors use our site" /></li>
      <li><L id="privacy.functionsCookies.text5" defaultMessage="_ga — allows to to distinguish users" /></li>
      <li><L id="privacy.functionsCookies.text6" defaultMessage="_gid — allows to to distinguish users" /></li>
      <li><L id="privacy.functionsCookies.text7" defaultMessage="intercom-id-[app_id] — an anonymous visitor identification cookie. Default expiration is 9 months" /></li>
      <li><L id="privacy.functionsCookies.text8" defaultMessage="intercom-session-[app_id] — is used to keep track of sessions and remember logins and conversations. Default expiration is 7 days" /></li>
      <li><L id="privacy.functionsCookies.text9" defaultMessage="_fbp — allows to to distinguish users" /></li>
      <li><L id="privacy.functionsCookies.text10" defaultMessage="settings-gdpr-GA-accept — used to save settings" /></li>
      <li><L id="privacy.functionsCookies.text11" defaultMessage="settings-gdpr-GTM-accept — used to save settings" /></li>
      <li><L id="privacy.functionsCookies.text12" defaultMessage="settings-gdpr-FB-accept — used to save settings" /></li>
    </ul>
    <h3><L id="privacy.manageCookies.title" defaultMessage="How to manage cookies" /></h3>
    <p><L id="privacy.manageCookies.subtitle" defaultMessage="You can manage and delete cookies:" /></p>
    <ul>
      <li><b><L id="privacy.manageCookies.text1" defaultMessage="Removing cookies from your device. " /></b><L id="privacy.manageCookies.text2" defaultMessage="You can remove all cookies that are already on your device by clearing the browsing history of your browser. This will remove all cookies from all websites that you have visited. Please note that you may also lose some saved information (e.g. saved login details, website preferences)." /></li>
      <li><b><L id="privacy.manageCookies.text3" defaultMessage="Managing website-specific cookies. " /></b><L id="privacy.manageCookies.text4" defaultMessage="For more detailed control over website-specific cookies, check the privacy and cookie settings in your preferred browser." /></li>
      <li><b><L id="privacy.manageCookies.text5" defaultMessage="Blocking cookies. " /></b><L id="privacy.manageCookies.text6" defaultMessage="You can set most modern browsers to prevent any cookies being placed on your device, but in this case may then have to manually adjust some preferences every time you visit a website or a page. Due to this, some functions may not work properly." /></li>
    </ul>
    <p><L id="privacy.useOfData.text1" defaultMessage="Hiveon uses the collected data for various purposes:" /></p>
    <ul>
       <li><L id="privacy.useOfData.text2" defaultMessage="To provide and maintain the Services" /></li>
       <li><L id="privacy.useOfData.text3" defaultMessage="To notify you about changes to our Services" /></li>
       <li><L id="privacy.useOfData.text4" defaultMessage="To allow you to participate in interactive features of our Services when you choose to do so" /></li>
       <li><L id="privacy.useOfData.text5" defaultMessage="To provide customer care and support" /></li>
       <li><L id="privacy.useOfData.text6" defaultMessage="To provide analysis or valuable information so that we can improve the Services" /></li>
       <li><L id="privacy.useOfData.text7" defaultMessage="To monitor the usage of the Services" /></li>
       <li><L id="privacy.useOfData.text8" defaultMessage="To detect, prevent and address technical issues" /></li>
    </ul>
    <h2><L id="privacy.transferOfData.title" defaultMessage="Transfer Of Data" /></h2>
    <p><L id="privacy.transferOfData.text1" defaultMessage="Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction." /></p>
    <p><L id="privacy.transferOfData.text2" defaultMessage="Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer." /></p>
    <p><L id="privacy.transferOfData.text3" defaultMessage="Hiveon will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information." /></p>
    <h2><L id="privacy.disclosureOfData.title" defaultMessage="Disclosure Of Data" /></h2>
    <h3><L id="privacy.disclosureOfData.text1" defaultMessage="Legal Requirements" /></h3>
    <p><L id="privacy.disclosureOfData.text2" defaultMessage="Hiveon may disclose your Personal Data in the good faith belief that such action is necessary to:" /></p>
    <ul>
       <li><L id="privacy.disclosureOfData.text3" defaultMessage="To comply with a legal obligation" /></li>
       <li><L id="privacy.disclosureOfData.text4" defaultMessage="To protect and defend the rights or property of Hiveon" /></li>
       <li><L id="privacy.disclosureOfData.text5" defaultMessage="To prevent or investigate possible wrongdoing in connection with the Services" /></li>
       <li><L id="privacy.disclosureOfData.text6" defaultMessage="To protect the personal safety of users of the Services or the public" /></li>
       <li><L id="privacy.disclosureOfData.text7" defaultMessage="To protect against legal liability" /></li>
    </ul>
    <h2><L id="privacy.securityOfData.title" defaultMessage="Security Of Data" /></h2>
    <p><L id="privacy.securityOfData.text1" defaultMessage="The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security." /></p>
    <h2><L id="privacy.links.title" defaultMessage="Links To Other Sites" /></h2>
    <p><L id="privacy.links.text1" defaultMessage="Our Services may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit." /></p>
    <p><L id="privacy.links.text2" defaultMessage="We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services." /></p>
    <h2><L id="privacy.childrenPrivacy.title" defaultMessage="Children's Privacy" /></h2>
    <p><L id="privacy.childrenPrivacy.text1" defaultMessage="Our Services does not address anyone under the age of 18 ('Children')." /></p>
    <p><L id="privacy.childrenPrivacy.text2" defaultMessage="We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers." /></p>
    <h2><L id="privacy.changesPrivacy.title" defaultMessage="Changes To This Privacy Policy" /></h2>
    <p><L id="privacy.changesPrivacy.text1" defaultMessage="We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page." /></p>
    <p><L id="privacy.changesPrivacy.text2" defaultMessage="We will let you know via email and/or a prominent notice on our Services, prior to the change becoming effective and update the 'effective date' at the top of this Privacy Policy." /></p>
    <p><L id="privacy.changesPrivacy.text3" defaultMessage="You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page." /></p>
    <h2><L id="privacy.contact.title" defaultMessage="Contact Information" /></h2>
    <p>
      <L
        id="privacy.contact.text1"
        defaultMessage="If you have any questions about this Privacy Policy, please contact us: {email}"
        values={{ email: 'bee@hiveos.farm' }}
      />
    </p>
  </div>
);


export default PrivacyContainer;
